@use './lib';

html,
body {
  padding: 0;
  margin: 0;
  font-family: lib.font("body");  
  font-size: lib.px2rem(lib.size('bodyFontSize'));
  color: lib.color('text');
}

a {
  color: inherit;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

button{
  font-family: inherit;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

svg{
  fill: lib.color('text');
  vertical-align: initial;
}

// loader

#psloader {
  width: 100%;
  height: 100%;
  background: #eeebe8;
  content: ' ';
  position: absolute;
  background-repeat: no-repeat;
  background-position: 50px 50px;
  background-size: 10%;
}

.loader:after {
  content: ' ';
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.25em;
  height: 1.25em;
  margin-top: -0.625em;
  margin-left: -0.625em;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  border-color: #0000;
  border-color: #e9516400 #e951644d #e9516499 #e95164;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  -webkit-animation: global-loading 1.25s linear infinite;
  animation: global-loading 1.25s linear infinite;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  font-family: helvetica, arial, sans-serif;
}


// Masorny Grid implemented in Top level Categories, Search page, Categories etc.

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
  margin-left: -10px;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
  padding-left: 10px;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
  margin-bottom: 10px;
}

@media screen and (min-width: 676px){

  .my-masonry-grid {
    margin-left: -10px;
  }

  .my-masonry-grid_column {
    padding-left: 10px;
  }

  .my-masonry-grid_column > div {
    margin-bottom: 10px;
  }

}

@media screen and (min-width: 920px){

  .my-masonry-grid {
    margin-left: -15px;
  }

  .my-masonry-grid_column {
    padding-left: 15px;
  }

  .my-masonry-grid_column > div {
    margin-bottom: 15px;
  }
}


.loader--bookgroup {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
}

.loader--search {
  top: 50%;
}

.loader--bookgroup:after,
.loader:after {
  content: ' ';
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.25em;
  height: 1.25em;
  margin-top: -0.625em;
  margin-left: -0.625em;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  border-color: #0000;
  border-color: #e9516400 #e951644d #e9516499 #e95164;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  -webkit-animation: global-loading 0.25s linear infinite;
  animation: global-loading 0.25s linear infinite;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  font-family: helvetica, arial, sans-serif;
}

.loader--bookgroup:after {
  margin-top: 3rem !important;
}

@keyframes global-loading {
  100% {
    transform: rotate(360deg);
  }
}

.hamburger__searchIcon--dark path,
.hamburger__searchIcon--open path {
  fill: #555;
}

button[type="reset"] {
  display: none;
}

input:valid ~ button[type="reset"] {
  display: block;
  outline: none;
  cursor: pointer;
}

.SlideWrapper {
  position: fixed; /* updates */
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 665;
  height: 100vh; /* Updates */
}

.SlideWrapper--close {
  animation: undim 0.4s ease-out forwards;
}

.SlideWrapper--open {
  animation: dim 0.3s ease-out forwards;
}

.SlideModal {
  transition: width 0.3s ease-out;
}

.h-textRight {
  text-align: right;
}

.h-overflowHidden {
  overflow: hidden !important;
}

.h-overflowAuto {
  overflow: auto !important;
}

.h-displayFlex {
  display: flex !important;  
}

.h-flexCol {
  flex-direction: column !important;
}

.h-flexSpaceBetween {
  justify-content: space-between !important;
}

.SlideModal {
  position: fixed;
  top: 0;
  bottom: 0;
  min-width: 320px;
  background-color: #322B2B;
  z-index: 666;
  width: 460px; /* updates */
}

.SlideModal--right {
  right: -50%;
}

.SlideModal--left {
  left: -50%;
}

.SlideModal--open.SlideModal--right {
  animation: slideIn--right 0.3s ease-out forwards;
}

.SlideModal--open.SlideModal--left {
  animation: slideIn--left 0.3s ease-out forwards;
}

.SlideModalContent { // effect
  padding: 2rem 2.5rem;

  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
      background: transparent; 
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 0.625rem;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
      background: #555; 
  }

}

@media (max-width: 919px) {
  .SlideModal--open {
    width: 100%;
  }    
}

.SlideModal--close.SlideModal--right {
  animation: slideOut--right 0.3s ease-out forwards;
}

.SlideModal--close.SlideModal--left {
  animation: slideOut--left 0.3s ease-out forwards;
}

.SlideModal__header {
  display: flex;
  align-items: center;
  background-color: transparent;
  overflow: hidden;
  box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.2);
  z-index: 667;
}

.SlideModal__footer {
  box-shadow: 0 -3px 2px 0 rgba(0, 0, 0, 0.2);
}

.SlideModal__footer {
  box-shadow: none;
}

.SlideModal__title {
  padding-left: 15px;
  color: #000;
}

.SlideModal {
  @include lib.mq($until: 'phone') {
    min-width: auto;
  }
}

@keyframes dim {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

@keyframes undim {
  from {
    background-color: rgba(0, 0, 0, 0.7);
  }
  to {
    background-color: transparent;
  }
}

@keyframes slideIn--right {
  0% {
    right: calc(-100%);
  }
  100% {
    right: 0;
  }
}

@keyframes slideOut--right {
  0% {
    right: 0;
  }
  100% {
    right: calc(-100%);
  }
}

@keyframes slideIn--left {
  0% {
    left: calc(-100%);
  }
  100% {
    left: 0;
  }
}

@keyframes slideOut--left {
  0% {
    left: 0;
  }
  100% {
    left: calc(-100%);
  }
}
